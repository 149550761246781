import React from "react"
import { graphql } from "gatsby"
import { ToastContainer } from "react-toastify"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"

// ---------- Themes colors ----------
import "../themes/green.scss"
// import "../themes/orange.scss"
import "./index.scss"

// ---------- Components ----------
import Hero from "../components/hero"
import Tabs from '../components/tabs'
// import Erlebnisnachmittag from '../contentfulModels/erlebnisnachmittag'
// import Ferienlager from '../contentfulModels/ferienlager'
// import Anlass from '../contentfulModels/anlass'
import Description from '../components/schuleErlebnisseCommon/description'
import ContactForm from '../components/schuleErlebnisseCommon/contactForm'
import Top from '../components/top'
import GridImages from '../components/gridImages'


const IndexPage = ({ data: {
  allContentfulBilderTrotteWinterangebot,
  allContentfulBilderTrottenstubli,
  allContentfulTrotteTexteWinterangebot,
  allContentfulTrotteTexteTrottenstubli,

  site,
} }) => {
  const { fullTitle, description } = site.siteMetadata

  // console.log(allContentfulTrotteTexte, 'allContentfulTrotteTexte')

  return (
    <div>
      <ToastContainer />
      <Hero title={fullTitle} description={description} />
      <Top content={allContentfulTrotteTexteWinterangebot.edges?.[0]?.node?.TopText?.raw} />
      <Tabs
        content={[
          <>
          {allContentfulBilderTrotteWinterangebot?.edges?.[0]?.node?.bilder && (
            <GridImages
              images={allContentfulBilderTrotteWinterangebot.edges[0].node.bilder}
              endText={allContentfulTrotteTexteWinterangebot.edges?.[0]?.node?.end?.raw}
              introText={allContentfulTrotteTexteWinterangebot.edges?.[0]?.node?.intro?.raw}
              introText2={allContentfulTrotteTexteWinterangebot.edges?.[0]?.node?.intro2?.raw}
              />
              )}

              {/* {allContentfulBilderTrotte2.edges?.[0]?.node?.bilder && (
            <GridImages
              images={allContentfulBilderTrotte2.edges[0].node.bilder}
              />
              )} */}


              <div style={{height:'50px'}}></div>
        </>,
          <>
            {/* <Description /> */}
            {allContentfulBilderTrottenstubli.edges?.[0]?.node?.bilder && (
            <GridImages
              images={allContentfulBilderTrottenstubli.edges[0].node.bilder}
              introText={allContentfulTrotteTexteTrottenstubli.edges?.[0]?.node?.TopText?.raw}
              endText={ allContentfulTrotteTexteTrottenstubli.edges?.[0]?.node?.end?.raw}
              />
              )}
              {/* {allContentfulAnlasseTrotte?.edges?.map((item) => <Anlass AnlasseTrotte={item?.node} />)} */}
          </>,
         
          <ContactForm/>,
        ]}
      />
    </div>
  )
}

export const projectsQuery = graphql`
  query FeaturedProjectsQuery {
    allContentfulBilderTrotteWinterangebot {
      edges {
        node {
          id
          bilder {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulBilderTrottenstubli {
      edges {
        node {
          bilder {
            file {
              url
            }
          }
        }
      }
    }
  

    allContentfulTrotteTexteWinterangebot
    {
      edges {
        node {
          TopText {
            raw
          }
          end {
            raw
          }
          intro {
            raw
          }
          intro2 {
            raw
          }
        }
      }
    }

    site {
      siteMetadata {
        fullTitle
        description
        about
      }
    }

    allContentfulTrotteTexteTrottenstubli
    {
      edges {
        node {
          TopText {
            raw
          }
          end {
            raw
          }
         
        }
      }
    }
  }
`

export default IndexPage
