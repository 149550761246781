import React, { Fragment, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { Container, Row } from "reactstrap"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { contentOptions } from '../contentfulModels/parser'

import './gridImages.scss'

const GridImages = ({
  images,
  isLoading,
  endText,
  introText,
  introText2,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const toggleLightbox = (selectedIndex) => {
    setSelectedIndex(selectedIndex)
    setIsLightboxOpen(!isLightboxOpen)
  }

  const views = images?.map((image) => ({ source: image.file.url }))

  return (
    <Fragment>
      {(!isLoading && images?.length) ? (
        <Container>
          <Row>
            <div className='texty'>

              {introText && documentToReactComponents(JSON.parse(introText), contentOptions)} 
              {introText2 && documentToReactComponents(JSON.parse(introText2), contentOptions)} 
            </div>
            <div className="gallery">

              {images.map(({ author, file, id, fluid }, j) => (
                <div
                  className="image"
                  onClick={() => toggleLightbox(j)}
                  key={file.url}
                >
                  <img
                    // fluid={{ src: file.url }}
                    src={file.url}
                  />
                </div>
              ))}

            </div>
            <div className='texty'>
              {endText && documentToReactComponents(JSON.parse(endText), contentOptions)}
            </div>
          </Row>
        </Container>
      ) : null}

      <ModalGateway>
        {isLightboxOpen && !isLoading ? (
          <Modal onClose={toggleLightbox}>
            <Carousel
              currentIndex={selectedIndex}
              style={{
                view: (base) => ({
                  ...base,
                  height: '80vh',
                }),
                container : () => ({
                  maxHeight: '80vh'
                })
              }}
              views={views}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  );
};

export default GridImages