import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
// import { Link } from "gatsby"
// import List2 from "../components/list2"

import { contentOptions } from '../contentfulModels/parser'

// import Square from "../assets/svg/solid/square.svg"

import "./about.scss"

const Top = ({
  content,
}) => {
  return (
    <div className="about pad">
      <Container>
        <Row>
          <Col md="12" lg="12">
            <section className="about-content text-secondary">
              {content
                && documentToReactComponents(JSON.parse(content), contentOptions)} 
              
              {/* <Link to="/about/#about" className="about-btn-lnk">
                <Button>Mehr über uns</Button>
              </Link> */}
            </section>
          </Col>
          
        </Row>
      </Container>
    </div>
  )
}

export default Top